// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-datenschutz-index-jsx": () => import("./../../../src/pages/datenschutz/index.jsx" /* webpackChunkName: "component---src-pages-datenschutz-index-jsx" */),
  "component---src-pages-impressum-index-jsx": () => import("./../../../src/pages/impressum/index.jsx" /* webpackChunkName: "component---src-pages-impressum-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-praxis-index-jsx": () => import("./../../../src/pages/praxis/index.jsx" /* webpackChunkName: "component---src-pages-praxis-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../../../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */)
}

